/// <reference path="o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Headers, BodyInit } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as ApiRequestOptionsModule from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

interface IRequestOptions {}

(() => {
    class ApiRequestOptions<T extends IRequestOptions> implements ApiRequestOptionsModule.ApiRequestOptions<T> {
        request: Request;
        parsedOptions: T;

        get headers(): Headers {
            return this.request.headers;
        }

        get body(): BodyInit {
            return this.request.body!;
        }

        constructor(request: Request, options: T) {
            this.request = request.clone();
            this.parsedOptions = options;
        }

        static parseFromRequest<T extends IRequestOptions>(request: Request, options: T) {
            return new ApiRequestOptions<T>(request, options);
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts')>({ ApiRequestOptions });
})();
